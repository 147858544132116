"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateAndSetForceRedirect = exports.redirectAndRemoveLink = exports.navigateToHomeActivity = exports.adminActivityRoute = exports.accountDetailsRoute = exports.forceRedirectDisabledRoute = void 0;
var api_1 = require("../auth/api");
var single_spa_1 = require("single-spa");
var constants_1 = require("./constants");
exports.forceRedirectDisabledRoute = [
    '/auth/login',
    '/onboarding/signup',
    '/',
    '/auth/v2/login',
];
exports.accountDetailsRoute = '/home/account-details';
exports.adminActivityRoute = '/home/activity';
var navigateToHomeActivity = function (tenant) { return __awaiter(void 0, void 0, void 0, function () {
    var redirect_url;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, localStorage.getItem('redirect_uri')];
            case 1:
                redirect_url = _a.sent();
                if ((tenant === null || tenant === void 0 ? void 0 : tenant.status) !== constants_1.TENANT_STATUS.ACTIVE) {
                    (0, single_spa_1.navigateToUrl)(exports.accountDetailsRoute);
                }
                else if (redirect_url &&
                    exports.forceRedirectDisabledRoute.indexOf(redirect_url) < 0) {
                    localStorage.removeItem('redirect_uri');
                    (0, single_spa_1.navigateToUrl)(redirect_url);
                }
                else {
                    (0, single_spa_1.navigateToUrl)(exports.adminActivityRoute);
                }
                return [2 /*return*/];
        }
    });
}); };
exports.navigateToHomeActivity = navigateToHomeActivity;
var redirectAndRemoveLink = function () {
    var redirectUrl = localStorage.getItem('redirect_uri');
    if (redirectUrl && exports.forceRedirectDisabledRoute.indexOf(redirectUrl) < 0) {
        localStorage.removeItem('redirect_uri');
        (0, single_spa_1.navigateToUrl)("".concat(window.location.origin).concat(redirectUrl));
    }
    else {
        return;
    }
};
exports.redirectAndRemoveLink = redirectAndRemoveLink;
function SelfOnboardingDataEvent() {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            window.addEventListener('fetchSelfOnboardingData', function (event) { return __awaiter(_this, void 0, void 0, function () {
                var _a, eventFromHeader, consented, isConsentFormEnabled, accountId, orgDetails, tenant, err_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = event.detail, eventFromHeader = _a.eventFromHeader, consented = _a.consented, isConsentFormEnabled = _a.isConsentFormEnabled;
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 4, , 5]);
                            accountId = sessionStorage.getItem(constants_1.SESSION_STORAGE_KEYS.ACCOUNT_ID);
                            return [4 /*yield*/, api_1.default.devx.post("tenants/".concat(accountId, "/actions/get-organization"))];
                        case 2:
                            orgDetails = (_b.sent()).data;
                            sessionStorage.setItem(constants_1.SESSION_STORAGE_KEYS.ORG_DETAIL, JSON.stringify(orgDetails));
                            return [4 /*yield*/, api_1.default.devx.get("/organizations/".concat(orgDetails === null || orgDetails === void 0 ? void 0 : orgDetails.id, "/tenants/").concat(accountId))];
                        case 3:
                            tenant = (_b.sent()).data;
                            sessionStorage.setItem(constants_1.SESSION_STORAGE_KEYS.TENANT_DETAIL, JSON.stringify(tenant));
                            if (!isConsentFormEnabled) {
                                (0, exports.navigateToHomeActivity)(tenant);
                            }
                            if (isConsentFormEnabled) {
                                if (consented) {
                                    (0, exports.navigateToHomeActivity)(tenant);
                                }
                                else {
                                    (0, single_spa_1.navigateToUrl)('/auth/terms');
                                }
                            }
                            if (eventFromHeader) {
                                window.location.reload();
                            }
                            return [3 /*break*/, 5];
                        case 4:
                            err_1 = _b.sent();
                            sessionStorage.removeItem(constants_1.SESSION_STORAGE_KEYS.ORG_DETAIL);
                            if (!isConsentFormEnabled) {
                                (0, single_spa_1.navigateToUrl)(exports.adminActivityRoute);
                            }
                            else {
                                if (consented) {
                                    (0, single_spa_1.navigateToUrl)(exports.adminActivityRoute);
                                }
                                else {
                                    (0, single_spa_1.navigateToUrl)('/auth/terms');
                                }
                            }
                            if (eventFromHeader) {
                                window.location.reload();
                            }
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            }); });
            return [2 /*return*/];
        });
    });
}
exports.default = SelfOnboardingDataEvent;
var validateAndSetForceRedirect = function () {
    if (exports.forceRedirectDisabledRoute.indexOf(window.location.pathname) < 0) {
        localStorage.setItem('redirect_uri', window.location.pathname);
    }
};
exports.validateAndSetForceRedirect = validateAndSetForceRedirect;
