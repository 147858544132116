"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var single_spa_1 = require("single-spa");
var single_spa_layout_1 = require("single-spa-layout");
var browser_rum_1 = require("@datadog/browser-rum");
var constants_1 = require("./utils/constants");
var sessionLifecycle_1 = require("./auth/sessionLifecycle");
var FullStory = require("@fullstory/browser");
var copilotRootConfig = function () {
    var routes = (0, single_spa_layout_1.constructRoutes)(document.querySelector('#single-spa-layout'));
    var applications = (0, single_spa_layout_1.constructApplications)({
        routes: routes,
        loadApp: function (_a) {
            var name = _a.name;
            return System.import(name);
        },
    });
    var layoutEngine = (0, single_spa_layout_1.constructLayoutEngine)({ routes: routes, applications: applications });
    var isEURegion = process.env.IS_EU_REGION === 'true';
    var fullStoryOrgId = process.env.FULLSTORY_ORG_ID;
    applications.forEach(single_spa_1.registerApplication);
    layoutEngine.activate();
    browser_rum_1.datadogRum.init(constants_1.dataDogConfig);
    if (!isEURegion && fullStoryOrgId) {
        FullStory.init({ orgId: process.env.FULLSTORY_ORG_ID });
    }
    browser_rum_1.datadogRum.startSessionReplayRecording();
    (0, single_spa_1.addErrorHandler)(function (err) {
        if ((0, single_spa_1.getAppStatus)(err.appOrParcelName) === single_spa_1.LOAD_ERROR) {
            System.delete(System.resolve(err.appOrParcelName));
        }
    });
    (0, sessionLifecycle_1.default)(browser_rum_1.datadogRum);
    (0, single_spa_1.start)();
};
exports.default = copilotRootConfig;
