"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var constants_1 = require("src/utils/constants");
var constructBaseUrl = function (domain) {
    var _a;
    var isEURegion = process.env.IS_EU_REGION === 'true';
    var baseUrl = '';
    var stage = process.env.STG;
    if (domain === 'devx') {
        var stageName = (_a = new URL(window.location.origin).hostname) === null || _a === void 0 ? void 0 : _a.split('.')[0];
        baseUrl =
            stageName === 'live'
                ? "//tenants.fabric.inc/v1"
                : "//".concat(stageName, ".tenants.fabric.inc/v1");
    }
    else if (isEURegion) {
        baseUrl = !stage
            ? 'https://live.eu.identity.fabric.inc/ums/v2'
            : 'https://eu.identity-sbx.fabric.inc/ums/v2';
    }
    else {
        baseUrl = !stage
            ? 'https://api.identity.fabric.zone/ums/v2'
            : "https://api.identity-".concat(process.env.STG, ".fabric.zone/ums/v2");
    }
    return baseUrl;
};
var axiosInstance = function () {
    return axios_1.default.create({
        responseType: 'json',
        headers: {
            Accept: 'application/json',
        },
    });
};
var interceptorsRequest = function (domainInstance, domain) {
    domainInstance.interceptors.request.use(function (request) {
        request.baseURL = constructBaseUrl(domain);
        request.headers.Authorization = "Bearer ".concat(sessionStorage.getItem(constants_1.SESSION_STORAGE_KEYS.ACCESS_TOKEN));
        if (domain === 'devx') {
            request.headers['x-fabric-tenant-id'] = sessionStorage.getItem(constants_1.SESSION_STORAGE_KEYS.ACCOUNT_ID);
        }
        return request;
    });
};
var domainsAxiosInstances = {
    identity: axiosInstance(),
    devx: axiosInstance(),
};
var identity = domainsAxiosInstances.identity, devx = domainsAxiosInstances.devx;
interceptorsRequest(identity, 'identity');
interceptorsRequest(devx, 'devx');
exports.default = domainsAxiosInstances;
