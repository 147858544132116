"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNLEASH_FEATURE_FLAGS = exports.TENANT_STATUS = exports.SESSION_STORAGE_KEYS = exports.dataDogConfig = void 0;
exports.dataDogConfig = {
    applicationId: process.env.DATA_DOG_APPLICATION_ID,
    clientToken: process.env.DATA_DOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: process.env.DATA_DOG_SERVICE,
    env: process.env.DATA_DOG_ENV,
    version: '1.0.0',
    sampleRate: parseInt(process.env.DATA_DOG_SAMPLE_RATE),
    replaySampleRate: parseInt(process.env.DATA_DOG_SAMPLE_RATE),
    trackInteractions: true,
    allowedTracingOrigins: [
        /https:\/\/.*\.fabric\.inc/,
        /https:\/\/.*\.fabric\.zone/,
    ],
};
var SESSION_STORAGE_KEYS;
(function (SESSION_STORAGE_KEYS) {
    SESSION_STORAGE_KEYS["ACCOUNT_ID"] = "accountId";
    SESSION_STORAGE_KEYS["ACCESS_TOKEN"] = "accessToken";
    SESSION_STORAGE_KEYS["ORG_DETAIL"] = "orgDetail";
    SESSION_STORAGE_KEYS["TENANT_DETAIL"] = "tenantDetail";
})(SESSION_STORAGE_KEYS = exports.SESSION_STORAGE_KEYS || (exports.SESSION_STORAGE_KEYS = {}));
exports.TENANT_STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    IN_PROGRESS: 'IN_PROGRESS',
};
exports.UNLEASH_FEATURE_FLAGS = {
    FEATURE_SELF_ONBOARDING: 'copilot-self-onboarding',
    FEATURE_L1_URL_CHANGES: 'copilot-authentication-url-update',
    FEATURE_CONSENT_FORM: 'copilot-authentication-consent',
    FEATURE_ANALYTICS_DASHBOARD: 'copilot-analytics-dashboard',
};
